.loading-custom {
  height: 60px;
  width: 60px;
  -webkit-animation: rotate 0.8s infinite linear;
  -moz-animation: rotate 0.8s infinite linear;
  -o-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
  border: 4px solid red;
  fill: #191919;
  border-right-color: transparent;
  border-radius: 50%;
}

div.loading-container.visible.active {
  background-color: white;
}
.loading-animation{
  left:30%;
  position:relative;
}

.loading-css .modal-wrapper {
  height: 20%;
  width: 40%;
  position: absolute;
  display: block;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background:transparent;
}
/*320px*/
@media screen and (min-width:320px){
  .loading-animation{
    left:15%;
    position:relative;
  }
}
/*375px*/
@media screen and (min-width:375px){
  .loading-animation{
    left:15%;
    position:relative;
    width:84px !important;
  }
}
/*768px*/
@media screen and (min-width:768px){
  .loading-animation{
    left:30%;
    position:relative;
    width:100px !important;
  }

  .sc-ion-modal-md-h {
    --box-shadow: none;
}
}
/*1024px*/
@media screen and (min-width:1024px){
  .loading-animation{
    left:35%;
    position:relative;
    width:120px !important;
  }
}